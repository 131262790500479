// Here you can add other styles

// tutorial boxes styles
.glowing-border:hover { 
    outline: none;
    border-color: #9ecaed;
    box-shadow: 0 0 10px #9ecaed;
    
}

.point {
    cursor: pointer;
}


// modal styles
.filter {
    margin-top: 10%;
    margin-left: 10%;
}

.modal-content:hover { 
    outline: none;
    border-color: #9ecaed;
    box-shadow: 0 0 10px #9ecaed;
    
}

// Date and time picker
.react-time-picker__wrapper {
    border: thin solid white !important;
}

.react-time-picker__wrapper:hover {
    border: thin solid gray;
}

// truncate nav bar entries
.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
  }

.trim {
    min-width: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

// Alert Modal
.modal-dialog {
    max-width: 1000px;
}

// Edit tutorial, remove students
.rmv_stu {
    cursor: pointer;
    float: right;
    opacity: 0.6;
}

.rmv_stu:hover {
    font-weight: bold;
    opacity: 1.0;
}

// Make buttons not overlap
.wide {
    width: 90px;
}

// Print
.print-source {
    display: none;
  }

@media all {
.pagebreak {
    display: none;
}
}

@media print {
html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
}
}

@media print {
    .print-source {
        display: block;}
    
    .print-cells {
        color: black;
    }
    .pagebreak {
        margin-top: 1rem;
        display: block;
        page-break-before: always;
      }
}

@page {
    size: auto;
    margin: 20mm;
}

